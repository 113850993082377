input[type='range'] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px;
  --seek-before-width: 0;
}

/* Track: webkit browsers */
input[type='range']::-webkit-slider-runnable-track {
  height: 15px;
  background: #ffd8ef;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type='range']::-moz-range-track {
  height: 15px;
  background: #ffd8ef;
  border-radius: 16px;
}

/* Thumb: webkit */
input[type='range']::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 15px;
  width: 15px;
  background-color: #ce0a7d;
  border-radius: 50%;
  border: 2px solid #ce0a7d;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #ce0a7d;
}

/* Thumb: Firefox */
input[type='range']::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #ce0a7d;
  border-radius: 50%;
  border: 1px solid #ce0a7d;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #ce0a7d;
}

#volume::-webkit-slider-runnable-track {
  height: 10px;
  background: #f3eeee;
}

/* Track: Mozilla Firefox */
#volume::-moz-range-track {
  height: 10px;
  background: #f3eeee;
}

#volume::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  background-color: #cccccc;
  border: 2px solid #cccccc;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #cccccc;
}

/* Thumb: Firefox */
#volume::-moz-range-thumb {
  height: 10px;
  width: 10px;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #cccccc;
}

@media (min-width: 640px) {
  /* Track: webkit browsers */
  input[type='range']::-webkit-slider-runnable-track {
    height: calc(15px * 0.75);
  }

  /* Track: Mozilla Firefox */
  input[type='range']::-moz-range-track {
    height: calc(15px * 0.75);
  }

  /* Thumb: webkit */
  input[type='range']::-webkit-slider-thumb {
    height: calc(15px * 0.75);
    width: calc(15px * 0.75);
  }

  /* Thumb: Firefox */
  input[type='range']::-moz-range-thumb {
    height: calc(15px * 0.75);
    width: calc(15px * 0.75);
  }

  #volume::-webkit-slider-runnable-track {
    height: calc(10px * 0.75);
  }

  /* Track: Mozilla Firefox */
  #volume::-moz-range-track {
    height: calc(10px * 0.75);
  }

  #volume::-webkit-slider-thumb {
    height: calc(10px * 0.75);
    width: calc(10px * 0.75);
  }

  /* Thumb: Firefox */
  #volume::-moz-range-thumb {
    height: calc(10px * 0.75);
    width: calc(10px * 0.75);
  }
}
